import {
  ChevronLeftIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { sendPasswordResetEmail } from 'firebase/auth'

import {
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
} from '../../constants/classConstants'
import { EMAIL_REGEX } from '../../constants/regex'
import AppLogo from '../../components/AppLogo'
import { auth } from '../../config/firebase'
import { useToastContext } from '../../contexts/ToastContext'

type ForgotPasswordForm = {
  email: string
}

const formSchema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email.')
    .matches(EMAIL_REGEX, 'Please enter a valid email address.')
    .trim()
    .test(
      'not-start-with-hu_kiddo',
      'Kiddo users are not allowed to reset passwords.',
      (value) => !value?.startsWith('hu_kiddo')
    ),
})

const ForgotPasswordScreen: React.FC = () => {
  const [sentEmail, setSentEmail] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const addToast = useToastContext()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<ForgotPasswordForm>({
    mode: 'all',
    resolver: yupResolver(formSchema),
  })

  const onSubmit = (data: ForgotPasswordForm) => {
    setLoading(true)
    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        setSentEmail(Boolean(data))
        setLoading(false)
      })
      .catch(() => {
        addToast('error', 'Something went wrong.')
        setLoading(false)
      })
  }

  const handleBackClick = () => {
    if (!sentEmail) navigate('/sign-in')
    else setSentEmail(false)
  }

  return (
    <div className="flex flex-col gap-8 text-text-primary">
      {/* Back link */}
      <div className="absolute flex items-start sm:top-6 sm:left-4 sm:w-2/3 xs:top-4 xs:left-2 xs:w-full">
        <button
          onClick={handleBackClick}
          className="mb-6 flex items-center text-base"
        >
          <ChevronLeftIcon className="h-5 w-5" />
          <span className="font-semibold">Back</span>
        </button>
      </div>

      {/* Logo */}
      <AppLogo classes="flex self-center" logoWithText />
      <div className="flex flex-col gap-2">
        <p className="text-center text-2xl font-semibold xs:text-base">
          Forgot Password
        </p>
        <p className="text-center text-base xs:text-sm">
          {!sentEmail
            ? 'Please enter the email you use to sign in.'
            : `We emailed you a link to reset your password.
              Please check your inbox or spam folder.`}
        </p>
      </div>

      {!sentEmail ? (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8">
          {/* Email */}
          <div>
            <label
              htmlFor="email"
              className="block text-base font-semibold xs:text-sm"
            >
              Email
            </label>
            <div className="relative gap-1">
              <input
                type="text"
                className={
                  errors.email && isSubmitted
                    ? inputErrorClass
                    : inputValidClass
                }
                placeholder="Email"
                {...register('email')}
              />
              {errors.email && isSubmitted && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors.email && isSubmitted && (
              <p className="text-sm text-status-error">
                {errors.email?.message}
              </p>
            )}
          </div>

          {/* Submit */}
          <button
            type="submit"
            className={`${primaryButtonClass} w-full`}
            disabled={loading}
          >
            {loading ? (
              <>
                <RefreshIcon className="loader h-5 w-5" /> Loading..
              </>
            ) : (
              'Submit'
            )}
          </button>

          {/* New to dot */}
          <p className="text-center text-base font-semibold xs:text-sm">
            New to Huddle Up?{' '}
            <Link
              to={'/sign-up/create-login'}
              className="text-cta-default underline"
            >
              Sign Up
            </Link>
          </p>
        </form>
      ) : (
        <Link
          to={'/sign-in'}
          className="text-center text-base font-semibold underline"
        >
          Go back to Sign-In
        </Link>
      )}
    </div>
  )
}

export default ForgotPasswordScreen
