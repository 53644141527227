import { useNavigate } from 'react-router-dom'

import { usePatient } from '../../../contexts/PatientProvider'
import type { ServiceLine } from '../../../types/ServiceLine'
import type { Therapist } from '../../../types/Therapist'
import useCurrentServiceLine from '../../../hooks/useCurrentServiceLine'
import type { CarePlan } from '../../../types/Patient'
import useGetProviders from '../../../queries/booking/UseGetProviders'
import { useAuth } from '../../../contexts/AuthProvider'
// import { useCheckProviderInInsuranceNetwork } from '../../../queries/booking/ProviderInsuranceNetwork'
// import { useGetInsuranceEligibility } from '../../../queries/insurance/InsuranceEligibility'

// INSURANCE REVERTED

interface NavigateAccordinglyToFlowProps {
  unassociatedInsurance: boolean
}

const useNavigateInsuranceComplete = (): [
  ({ unassociatedInsurance }: NavigateAccordinglyToFlowProps) => void,
  boolean
] => {
  const { user } = useAuth()
  const { patient } = usePatient()
  const navigate = useNavigate()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const patientCarePlans: CarePlan[] = patient?.carePlans?.filter(
    (c) => !c.isIep
  )

  const { data: resultProviders, isLoading: isLoadingTherapists } =
    useGetProviders({
      clientId: user?.data?.clientId,
      patients: [patient],
      onlyServiceLines: [currentServiceLine],
      withFirstAvailability: false,
      ignoreTakingNewPrivatePatients: true,
    })

  const currentProvider = resultProviders
    ?.find((rp) => rp.patient.id === patient?.id)
    ?.therapists?.find(
      (t: Therapist) =>
        t.id ===
        patientCarePlans.find(
          (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
        ).providerId
    )
  // const isProviderInsuranceNetworkEnabled = Boolean(
  //   !patient.unassociatedInsurance &&
  //     patient.insurance?.id &&
  //     currentProvider?.id
  // )

  // const {
  //   data: providerInsuranceNetwork,
  //   isLoading: isLoadingProviderInsuranceCheck,
  // } = useCheckProviderInInsuranceNetwork({
  //   patient,
  //   serviceType: currentServiceLine.serviceType,
  //   language: patient.preferredLanguage,
  //   licenseJurisdiction: states.find(
  //     (state: State) => state?.name === patient?.state
  //   )?.abbrev,
  //   providerId: currentProvider?.id,
  //   insuranceId: patient.insurance?.id,
  //   enabled: isProviderInsuranceNetworkEnabled,
  // })

  // const shouldCheckInsuranceEligibility = Boolean(
  //   patient.id &&
  //     patient.insurance?.id &&
  //     !patient.unassociatedInsurance &&
  //     currentServiceLine?.serviceType
  // )

  // const {
  //   data: insuranceEligibilityCheck,
  //   isLoading: isLoadingInsuranceEligibilityCheck,
  // } = useGetInsuranceEligibility({
  //   patientId: patient.id,
  //   insuranceId: patient.insurance?.id,
  //   serviceType: currentServiceLine.serviceType,
  //   enabled: shouldCheckInsuranceEligibility,
  // })

  const isLoadingNavigateAccordinglyToFlow = isLoadingTherapists
  // ||
  // (isProviderInsuranceNetworkEnabled && isLoadingProviderInsuranceCheck) ||
  // (shouldCheckInsuranceEligibility && isLoadingInsuranceEligibilityCheck)

  const navigateAccordinglyToFlow = ({
    unassociatedInsurance,
  }: NavigateAccordinglyToFlowProps): void => {
    if (isLoadingNavigateAccordinglyToFlow) return

    // eslint-disable-next-line no-console
    console.log(unassociatedInsurance)

    // // Change API: Eligibility Failed OR Insurance 'OTHER'
    // if (
    //   (insuranceEligibilityCheck &&
    //     !insuranceEligibilityCheck.insuranceEligibile) ||
    //   unassociatedInsurance
    // ) {
    //   navigate(
    //     `/booking-non-sponsored/${currentServiceLine.url}/insurance-info/pending-verification`
    //   )
    //   return
    // }
    // Change API: Eligibility NOT Failed
    // else {
    // const isCurrentTherapistInNetwork =
    //   providerInsuranceNetwork?.currentProviderInNetwork

    // in network
    // if (isCurrentTherapistInNetwork) {
    // const areThereOtherTherapistsToChooseFrom: boolean =
    //   providerInsuranceNetwork?.otherProvidersInNetwork?.length > 0

    // other therapists in network besides current one
    // if (areThereOtherTherapistsToChooseFrom)
    //   return navigate(
    //     `/booking-non-sponsored/${currentServiceLine.url}/change-therapist`
    //   )
    // // no other therapists in network besides current one
    // else
    return navigate(
      `/booking/${currentServiceLine.url}/providers` +
        (currentProvider ? `/${currentProvider.id}` : '')
    )
    // }
    // // out of network
    // else {
    //   // MEDICAID Insurance
    //   if (patient.insurance?.insurance?.insuranceType === 'Medicaid') {
    //     navigate(
    //       `/booking-non-sponsored/${currentServiceLine.url}/therapist-no-accept`
    //     )
    //     return
    //   }
    //   // REGULAR Insurance
    //   else {
    //     navigate(
    //       `/booking-non-sponsored/${currentServiceLine.url}/change-therapist`
    //     )
    //     return
    //   }
    // }
    // }
  }

  return [navigateAccordinglyToFlow, isLoadingNavigateAccordinglyToFlow]
}

export default useNavigateInsuranceComplete
