import React from 'react'
import { useNavigate } from 'react-router-dom'
import { flushSync } from 'react-dom'
import cloneDeep from 'lodash.clonedeep'

import { EligibilityForm } from '../auth/eligibility/VerifyEligibility'
import type { EligibilityInfo } from '../../types/Register'
import { usePatient } from '../../contexts/PatientProvider'
import { useClaimPerson } from '../../mutations/eligibility/ClaimPerson'
import { useGrantAccessPerson } from '../../mutations/eligibility/GrantAccessPerson'
import { auth } from '../../config/firebase'
import { useAuth } from '../../contexts/AuthProvider'
import { useToastContext } from '../../contexts/ToastContext'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { ONBOARDING_STEP } from '../../types/Patient'

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.eligibility },
}

const Eligibility: React.FC = () => {
  const addToast = useToastContext()
  const { user, setUser } = useAuth()
  const { patient, setPatient } = usePatient()
  const navigate = useNavigate()
  const forSelf: boolean = patient?.relationship?.key === 'myself'
  const { mutate: callClaimPerson, isLoading: isLoadingClaim } =
    useClaimPerson()
  const { mutate: callGrantAccessPerson, isLoading: isLoadingGrant } =
    useGrantAccessPerson()

  const onSuccessEligibility = async (userInfo: EligibilityInfo) => {
    trackMixPanel(MIXPANEL_DATA)

    const token = await auth.currentUser.getIdToken()
    const claimOrAccessParams = {
      bearerToken: token,
      clientId: user.data.clientId,
      dateOfBirth: userInfo?.birthDate,
      externalId: userInfo?.email || userInfo?.externalId,
    }

    const onError = () => navigate('failed')
    const onSuccess = (patientId: string) => {
      if (!patientId) {
        addToast('error', 'Something went wrong.')
        return
      }

      const newPatient = {
        ...patient,
        ...userInfo,
        id: patientId,
        carePlans: [],
      }
      const newUser = cloneDeep(user)
      newUser.roster.push(newPatient)

      flushSync(() => {
        setUser(newUser)
        setPatient(newPatient)

        const queryParams: string = Object.entries({
          ...userInfo,
          patientId,
          personId: userInfo?.personId,
          forSelf,
        })
          .filter(
            ([key, value]) =>
              key !== undefined && value !== undefined && value !== null
          )
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&')

        navigate(`/onboarding/build-profile?${queryParams}`)
      })
    }

    if (forSelf) callClaimPerson(claimOrAccessParams, { onError, onSuccess })
    else callGrantAccessPerson(claimOrAccessParams, { onError, onSuccess })
  }

  return (
    <div className="max-w-lg">
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="whitespace-pre-wrap text-center text-2xl font-semibold">
          Let's verify {forSelf ? 'your' : 'their'} eligibility
        </p>
        <p className="text-center text-sm">
          Please provide the following student information as it would appear on
          school files
        </p>
      </div>

      <EligibilityForm
        isLoadingExternal={isLoadingClaim || isLoadingGrant}
        userForSelf={forSelf}
        onSuccess={onSuccessEligibility}
        onFailRedirectUrl="failed"
      />
    </div>
  )
}

export default Eligibility
