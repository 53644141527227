import { InformationCircleIcon, XIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'

const SessionsTabMessaging: React.FC = () => {
  const [seeMessaging, setSeeMessaging] = useState(
    !localStorage.getItem('HU_HIDE_SESSIONS_TAB_INFO')
  )

  if (!seeMessaging) return null

  return (
    <div className="flex items-center justify-between rounded-2xl bg-components-paleBlue p-4">
      <div className="flex flex-col">
        <div className="flex items-center gap-1">
          <InformationCircleIcon className="h-4 w-4" />
          <p className="text-base font-bold">What you can view on this tab</p>
        </div>
        <p className="test-base font-normal">
          You can view all the upcoming and previous sessions provided by Huddle
          Up Therapists.
        </p>
      </div>

      <button
        onClick={() => {
          setSeeMessaging(false)
          localStorage.setItem('HU_HIDE_SESSIONS_TAB_INFO', 'true')
        }}
      >
        <XIcon className="h-5 w-5 text-text-label" />
      </button>
    </div>
  )
}

export default SessionsTabMessaging
