import mixpanel from 'mixpanel-browser'

export function initMixpanel() {
  const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN
  const mixpanelProxy = import.meta.env.VITE_MIXPANEL_PROXY

  mixpanel.init(mixpanelToken, {
    api_host: mixpanelProxy,
  })
}
