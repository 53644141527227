import { useMutation } from '@tanstack/react-query'

import { auth } from '../../config/firebase'

interface CancelBookingProps {
  appointmentId: string
}

const cancelBooking = async ({
  appointmentId,
}: CancelBookingProps): Promise<boolean> => {
  const token: string = await auth.currentUser.getIdToken()

  const r = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_APPOINTMENTS_URL
    }v1/appointments/cancel`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids: [appointmentId],
        reason: 'EMERGENCY',
        reasonText: '',
      }),
    }
  )

  return r.status === 200
}

export const useCancelBooking = () =>
  useMutation((params: CancelBookingProps) => cancelBooking(params))
