import type { Patient } from '../types/Patient'
import { useAuth } from '../contexts/AuthProvider'

const useRanOutOfBulkSessionsAndPatientPays = () => {
  const { user } = useAuth()

  return user.roster.some((p: Patient) =>
    p?.carePlans.some(
      (cp) =>
        !cp?.isIep &&
        cp?.bulkModel?.isBulkModel &&
        !cp?.bulkModel?.remainingSessionsOnBulkModel &&
        user.products.find((p) => p.id === cp.productId)?.additionalSessions ===
          'PATIENT_PAY'
    )
  )
}

export default useRanOutOfBulkSessionsAndPatientPays
