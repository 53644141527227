import React from 'react'
import { useNavigate } from 'react-router-dom'

import GlobalLoader from '../../components/GlobalLoader'
import {
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import {
  SERVICE_LINES_ARRAY,
  // THERAPIST_NETWORK_INFORMATIONS,
} from '../../constants/serviceLine'
import { LICENSE_CREDENTIAL } from '../../constants/values'
import { usePatient } from '../../contexts/PatientProvider'
import { formatPossessiveNoun } from '../../helpers/generic'
import type { ServiceLine } from '../../types/ServiceLine'
import type { Therapist } from '../../types/Therapist'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { CarePlan } from '../../types/Patient'
// import { ProviderStatus } from '../booking/Providers'
// import { useCheckProviderInInsuranceNetwork } from '../../queries/booking/ProviderInsuranceNetwork'
import { useProviderContext } from '../../contexts/ProviderContext'
import useGetProviders from '../../queries/booking/UseGetProviders'
import { useAuth } from '../../contexts/AuthProvider'

// INSURANCE REVERTED

const MaybeChangeTherapist: React.FC = () => {
  const { user } = useAuth()
  const { patient } = usePatient()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const { setWantDifferentTherapist } = useProviderContext()
  const navigate = useNavigate()
  const patientCarePlans: CarePlan[] = patient?.carePlans?.filter(
    (c) => !c.isIep
  )

  const forSelf: boolean = patient?.relationship?.key === 'myself'

  const { data: resultProviders, isLoading: isLoadingTherapists } =
    useGetProviders({
      clientId: user?.data?.clientId,
      patients: [patient],
      onlyServiceLines: [currentServiceLine],
      withFirstAvailability: false,
      ignoreTakingNewPrivatePatients: true,
    })

  const currentProvider = resultProviders
    ?.find((rp) => rp.patient.id === patient?.id)
    ?.therapists?.find(
      (t: Therapist) =>
        t.id ===
        patientCarePlans.find(
          (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
        ).providerId
    )

  // const isProviderInsuranceNetworkEnabled = Boolean(
  //   !patient.unassociatedInsurance &&
  //     patient.insurance?.id &&
  //     currentProvider?.id
  // )

  // const {
  //   data: providerInsuranceNetwork,
  //   isLoading: isLoadingProviderInsuranceCheck,
  // } = useCheckProviderInInsuranceNetwork({
  //   patient,
  //   serviceType: currentServiceLine.serviceType,
  //   language: patient.preferredLanguage,
  //   licenseJurisdiction: states.find(
  //     (state: State) => state?.name === patient?.state
  //   )?.abbrev,
  //   providerId: currentProvider?.id,
  //   insuranceId: patient.insurance?.id,
  //   enabled: isProviderInsuranceNetworkEnabled,
  // })

  if (
    isLoadingTherapists
    // ||
    // (isProviderInsuranceNetworkEnabled && isLoadingProviderInsuranceCheck)
  )
    return <GlobalLoader />

  const handleKeepTherapist = () =>
    navigate(
      `/booking/${currentServiceLine.url}/providers/${currentProvider.id}`
    )

  const handleViewMyOptions = () => {
    setWantDifferentTherapist(true)
    navigate(`/booking/${currentServiceLine.url}/providers`)
  }

  return (
    <div className="max-w-4xl text-center">
      <div className="flex flex-col gap-6">
        <h2 className="text-lg font-semibold sm:text-2xl">
          Would you like to change{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : formatPossessiveNoun(patient.firstName)}
          </span>{' '}
          therapist?
        </h2>
        <p className="text-sm font-normal sm:text-base">
          {/* {providerInsuranceNetwork.currentProviderInNetwork
            ? `${
                forSelf ? 'Your' : formatPossessiveNoun(patient.firstName)
              } insurance information has changed and ${
                forSelf ? 'you' : 'they'
              } may have access to different in-network therapists.`
            : `Looks like ${
                forSelf ? 'your' : formatPossessiveNoun(patient.firstName)
              } therapist may not be covered by ${
                forSelf ? 'your' : 'their'
              } insurance network, which could lead to higher cost of therapy.`}
          <br /> */}
          {`Would you like to see ${
            forSelf ? 'your' : formatPossessiveNoun(patient.firstName)
          } therapist options?`}
        </p>
        <div className="mx-auto flex max-w-sm flex-row items-center gap-6 text-left sm:gap-16">
          <img
            className="h-14 w-14 rounded-full object-cover sm:h-30 sm:w-30 sm:border-4 sm:border-components-paleBlue"
            src={currentProvider.src}
            alt="Therapist"
          />
          <div className="flex flex-col items-start gap-0 sm:gap-2">
            <h1 className="text-sm font-semibold sm:text-2xl">
              {currentProvider.preferredName}
            </h1>
            <h2 className="text-label text-left text-xs font-semibold sm:text-base">
              {currentProvider?.licenseCredential ||
                LICENSE_CREDENTIAL[
                  SERVICE_LINES_ARRAY.find(
                    (line: ServiceLine) =>
                      line.displayName === currentProvider.serviceLine
                  ).serviceType
                ]}
            </h2>
            {/* {isProviderInsuranceNetworkEnabled &&
              providerInsuranceNetwork &&
              !isLoadingProviderInsuranceCheck && (
                <ProviderStatus
                  therapistNetworkInformation={
                    providerInsuranceNetwork.currentProviderInNetwork
                      ? THERAPIST_NETWORK_INFORMATIONS.inNetwork
                      : THERAPIST_NETWORK_INFORMATIONS.outOfNetwork
                  }
                />
              )} */}
          </div>
        </div>
      </div>
      <div className="mx-auto grid w-full max-w-lg grid-cols-2 gap-2 sm:gap-4">
        <button
          type="button"
          className={tertiaryButtonClass}
          onClick={() => handleViewMyOptions()}
        >
          Yes, view my options
        </button>

        <button
          type="button"
          className={primaryButtonClass}
          onClick={() => handleKeepTherapist()}
        >
          No, keep my therapist
        </button>
      </div>
    </div>
  )
}

export default MaybeChangeTherapist
