import { useQuery } from '@tanstack/react-query'

import getVanityUrl from '../../helpers/getVanityUrl'

export const useGetClientInfo = () =>
  useQuery(['getClientInfo'], async () => getClientInfo())

const getClientInfo = async () => {
  const vanityUrl: string = getVanityUrl()

  const result: any = await fetch(
    `${
      import.meta.env.VITE_DOTCOM_JAVA_CLIENTS_URL
    }v1/clients/get-id-by-vanity-url/${vanityUrl}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then((response) => response.json())

  return {
    clientId: result?.id,
    clientType: result?.clientType,
    importer4thId: result?.importer4thId,
    clientName: result?.name,
    requireEligibilityFile: result?.requireEligibilityFile,
  }
}
