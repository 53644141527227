import React, { useEffect, useState } from 'react'

import { SERVICE_LINES_ARRAY } from '../../../constants/serviceLine'
import { useAuth } from '../../../contexts/AuthProvider'
import { enumeratedElementsFromArray } from '../../../helpers/generic'
import type { ResultGetProviders } from '../../../queries/booking/UseGetProviders'
import type { CarePlan } from '../../../types/Patient'
import type { ServiceLine } from '../../../types/ServiceLine'
import DashboardElement from '../DashboardElement'
import handleClickSupportEmail from '../../../helpers/supportClick'

const NoAvailableTherapists: React.FC<{
  resultProviders: ResultGetProviders[]
}> = ({ resultProviders }) => {
  const { user } = useAuth()
  const [unavailableServiceLines, setUnavailableServiceLines] =
    useState<string>(null)

  useEffect(() => {
    if (!resultProviders?.length || !user?.roster?.length) return

    const serviceLinesForPatient: string[] = []

    resultProviders.forEach((result: ResultGetProviders) => {
      const unavailableServiceLines = result.patient?.carePlans
        .filter(
          (cp: CarePlan) =>
            !cp.isIep &&
            !result.availableServiceLinesNames.includes(cp.displayName)
        )
        .map((cp: CarePlan) => cp.displayName)
        .map(
          (serviceLineDisplayName: string) =>
            SERVICE_LINES_ARRAY.find(
              (sl: ServiceLine) => sl.displayName === serviceLineDisplayName
            ).type
        )
      if (unavailableServiceLines?.length)
        serviceLinesForPatient.push(
          `${enumeratedElementsFromArray(
            unavailableServiceLines,
            'or'
          )} therapists for ${
            result.patient?.relationship?.key === 'myself'
              ? 'you'
              : result.patient.firstName
          }`
        )
    })

    setUnavailableServiceLines(
      enumeratedElementsFromArray(serviceLinesForPatient)
    )
  }, [resultProviders, user?.roster?.length])

  const patientNames: string =
    resultProviders &&
    enumeratedElementsFromArray(
      resultProviders
        ?.filter(
          (el: ResultGetProviders) =>
            el.availableServiceLinesNames?.length !==
              el.patient?.carePlans?.filter((cp) => !cp.isIep)?.length &&
            !el.patient.isIepOnly &&
            !el.patient.isDisabled
        )
        .map((result: ResultGetProviders) =>
          result.patient?.relationship?.key === 'myself'
            ? 'you'
            : result.patient.firstName
        )
    )

  if (!user?.roster?.length || !unavailableServiceLines || !patientNames) return

  return (
    <DashboardElement
      noGap
      title={
        <span>
          Sorry, we are still looking for the right therapist for {patientNames}
          !
        </span>
      }
    >
      <div>
        <p className="text-base xs:text-sm">
          Unfortunately, we do not have any {unavailableServiceLines} available
          for booking at this time.
        </p>
        <p className="text-base xs:text-sm">
          If you have any questions, please contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>
    </DashboardElement>
  )
}

export default NoAvailableTherapists
