import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { ServiceLine } from '../../types/ServiceLine'
import { fetchProviders } from './UseGetProviders'

interface ProvidersLengthProps {
  serviceLines: ServiceLine[]
  licenseJurisdiction: string
  language?: string
  options?: UseQueryOptions
}

export interface ProvidersLengthReturnValue {
  social_work?: number
  speech_therapy?: number
  occupational_therapy?: number
}

// get number of providers by service line and selected state
const getProvidersLength = async ({
  serviceLines,
  licenseJurisdiction,
  language = '',
}: ProvidersLengthProps): Promise<ProvidersLengthReturnValue> => {
  const returnValue: ProvidersLengthReturnValue = {}

  for (let i = 0; i < serviceLines?.length; i++) {
    const serviceType = serviceLines[i].serviceType
    const result = await fetchProviders(
      `service_type=${serviceType}&language=${language}&license_jurisdiction=US-${licenseJurisdiction}`
    )
    returnValue[serviceType] = result?.length || 0
  }

  return returnValue
}

export const useGetProvidersLength = (
  { options = {}, ...params }: ProvidersLengthProps,
  deps = params
) =>
  useQuery(
    ['getProvidersLength', deps],
    async () => getProvidersLength(params),
    options
  )
