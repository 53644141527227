import { useMutation } from '@tanstack/react-query'

interface CreateNewKiddoUser {
  parentPersonId: string
  token: string
  clientId: string
}

const createNewKiddoUser = async ({
  parentPersonId,
  token,
  clientId,
}: CreateNewKiddoUser): Promise<string> => {
  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_BFF_URL}user-kiddo`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        parentPersonId,
        clientId,
      }),
    }
  ).then((response) => response.json())

  return result.dctUserId
}

export const useCreateNewKiddoUser = () =>
  useMutation((params: CreateNewKiddoUser) => createNewKiddoUser(params))
