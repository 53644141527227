import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { flushSync } from 'react-dom'

import { useAuth } from '../../../contexts/AuthProvider'
import type { CarePlan, Patient } from '../../../types/Patient'
import DashboardElement from '../DashboardElement'
import DottedLargeButton from '../../../components/DottedLargeButton'
import { usePatient } from '../../../contexts/PatientProvider'
import getAvatarSrc from '../../../helpers/getAvatarSrc'
import { uniqueCarePlans } from '../../../helpers/utils'

const Roster = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { setPatient } = usePatient()

  const goToPatient = (selectedPatient: Patient) =>
    flushSync(() => {
      setPatient(selectedPatient)
      navigate(`/patient/${selectedPatient.id}`)
    })

  const accountHolderIsBelow16 = user?.roster?.some(
    (patient: Patient) =>
      patient?.age < 16 && patient?.relationship?.key === 'myself'
  )

  const accountHolderIsSeparateLogin = user.roster.some(
    (p) => p?.relationship?.key === 'myself' && p?.isSeparateLogin
  )

  return (
    <DashboardElement
      headerTitle="My Roster"
      noContentPadding={Boolean(user?.roster?.length)}
      addAnotherPersonButton={
        Boolean(user?.roster?.length) &&
        !accountHolderIsBelow16 &&
        !accountHolderIsSeparateLogin
      }
      noStyling={Boolean(user?.roster?.length)}
    >
      {React.Children.toArray(
        user?.roster?.map((patient: Patient) => (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => goToPatient(patient)}
            onClick={() => goToPatient(patient)}
            className="flex cursor-pointer justify-between rounded-2xl border border-components-fields bg-white p-4 pl-8 shadow-lg xs:p-4"
          >
            {/* Info */}
            <div className="flex items-center gap-10 xs:gap-4">
              {/* Image */}
              <img
                src={getAvatarSrc(user, patient)}
                alt="patient-avatar"
                className="h-24 w-24 xs:h-11 xs:w-11"
              />

              {/* Name, etc. */}
              <div className="flex flex-col sm:ml-10 sm:gap-6 xs:gap-2">
                <div className="flex flex-col gap-1">
                  <p className="font-semibold text-text-primary sm:text-2xl xs:text-base">
                    {patient.firstName} {patient.lastName}{' '}
                    <span className="font-medium text-text-label">
                      {patient.age} yo
                    </span>
                  </p>
                </div>

                <div className="hidden gap-2 lg:flex">
                  {React.Children.toArray(
                    uniqueCarePlans(patient?.carePlans)?.map((cp: CarePlan) => (
                      <div className="rounded-lg border border-components-fields sm:py-2 sm:px-4 xs:my-1 xs:py-1 xs:px-2">
                        <p className="text-sm font-semibold text-text-secondary">
                          {cp.displayName}
                        </p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>

            {/* Go to patient screen */}
            <div className="flex items-center rounded-lg bg-components-paleBlue">
              <ChevronRightIcon className="h-14 w-14 text-cta-default xs:h-8 xs:w-8" />
            </div>
          </div>
        ))
      )}

      {/* No users */}
      {Boolean(!user?.roster?.length) && (
        <DottedLargeButton
          title="Add A Person"
          onClick={() => navigate('/onboarding/your-story')}
        />
      )}
    </DashboardElement>
  )
}

export default Roster
