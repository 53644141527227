import { useQuery } from '@tanstack/react-query'

import { auth } from '../../config/firebase'
import { toCamelCase } from '../../helpers/serializers'
import type { Therapist } from '../../types/Therapist'
import { toTherapist } from '../../helpers/transformers'
import type { Patient } from '../../types/Patient'

interface CheckProviderInInsuranceNetworkProps {
  patient: Partial<Patient>
  serviceType: string
  language?: string
  licenseJurisdiction?: string
  ignoreTakingNewPrivatePatients?: boolean
  sortBy?: string
  withFirstAvailability?: boolean
  durationInMinutes?: number
  providerId: string
  insuranceId: string
  enabled: boolean
}

interface CheckProviderInInsuranceNetworkReturnValue {
  currentProviderInNetwork: boolean
  otherProvidersInNetwork: Therapist[]
}

export const useCheckProviderInInsuranceNetwork = (
  params: CheckProviderInInsuranceNetworkProps
) =>
  useQuery(
    [
      'checkProviderInInsuranceNetwork',
      {
        serviceType: params.serviceType,
        licenseJurisdiction: params.licenseJurisdiction,
        language: params.language,
      },
    ],
    async () => checkProviderInInsuranceNetwork(params),
    { enabled: Boolean(params.enabled) }
  )

export const checkProviderInInsuranceNetwork = async ({
  ...params
}: CheckProviderInInsuranceNetworkProps): Promise<CheckProviderInInsuranceNetworkReturnValue> => {
  const token: string = await auth.currentUser.getIdToken()
  const result = await fetch(
    `${import.meta.env.VITE_DOTCOM_URL}api/v1/providers/${
      params.providerId
    }/provider_in_network?${
      params.insuranceId ? `insurance_id=${params.insuranceId}` : ''
    }`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())

  if (result?.error) {
    throw new Error(result.error)
  }

  const camelCased: CheckProviderInInsuranceNetworkReturnValue =
    toCamelCase(result)

  return {
    currentProviderInNetwork: camelCased?.currentProviderInNetwork,
    otherProvidersInNetwork:
      camelCased?.otherProvidersInNetwork?.map((t: Therapist) =>
        toTherapist(t)
      ) || [],
  }
}
