import React, { useEffect } from 'react'
// import { useNavigate } from 'react-router-dom'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'
import { RefreshIcon } from '@heroicons/react/solid'

import { primaryButtonClass } from '../../constants/classConstants'
import { usePatient } from '../../contexts/PatientProvider'
import { formatPossessiveNoun } from '../../helpers/generic'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import { useAuth } from '../../contexts/AuthProvider'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import useNavigateInsuranceComplete from './hooks/useNavigateInsuranceComplete'
import useSeenCOSPage from '../../hooks/useSeenCOSPage'

// INSURANCE REVERTED

const UpdatePaymentOptions: React.FC = () => {
  const { markCOSPageAsSeen } = useSeenCOSPage()
  const { user, setUser } = useAuth()
  const { patient, setPatient } = usePatient()
  const currentServiceLine = useCurrentServiceLine()
  // const navigate = useNavigate()
  const [navigateAccordinglyToFlow, isLoadingNavigateAccordinglyToFlow] =
    useNavigateInsuranceComplete()

  const forSelf: boolean = patient?.relationship?.key === 'myself'

  const handleContinueBooking = async () => {
    const newPatient = cloneDeep(patient)

    const matchingCarePlan = patient?.carePlans.find((cp) =>
      !cp.isIep &&
      cp?.displayName === currentServiceLine?.displayName &&
      cp.sessions.some((s) => s.status === 'completed')
        ? // current sessions is not assessment
          cp.remainingSessions <= 0
        : // is assessment
          cp.remainingSessions <= 1 &&
          !cp.bookingNonSponsoredInformationConfirmed
    )

    if (matchingCarePlan) {
      matchingCarePlan.bookingNonSponsoredInformationConfirmed = true
    }

    // Find and update the patient in the user.roster list
    const updatedRoster = user.roster.map((rosterPatient) => {
      if (rosterPatient.id === newPatient.id) {
        return newPatient
      }
      return rosterPatient
    })

    // Update the user object with the updated roster
    const updatedUser = { ...user, roster: updatedRoster }

    flushSync(() => {
      setPatient(newPatient)
      setUser(updatedUser)
      // if (!patient?.insurance) navigate('../insurance-info/add')
      // else if (patient.unassociatedInsurance)
      //   navigate('../insurance-info/pending-verification')
      // else navigate('../insurance-info/confirm')
      navigateAccordinglyToFlow({ unassociatedInsurance: false })
    })
  }

  // mark in local storage that user seen the COS page
  useEffect(() => {
    markCOSPageAsSeen()
  }, [])

  return (
    <div className="max-w-xl items-center justify-center">
      <div className="flex flex-col gap-6">
        <h1 className="text-center text-2xl font-semibold xs:text-lg">
          Update on{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : `${formatPossessiveNoun(patient.firstName)}`}
          </span>{' '}
          therapy payment options
        </h1>
        <p className="whitespace-pre-wrap text-center text-base xs:text-sm">
          Please note that {forSelf ? 'you have' : `${patient.firstName} has`}{' '}
          used up all covered sessions, and any future sessions will need to be
          paid out of pocket.{'\n\n'} If you have any questions or concerns
          about payment options, please don't hesitate to contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline xs:text-sm"
          >
            support@huddleupcare.com
          </a>
        </p>
      </div>
      <div className="flex w-full max-w-lg flex-col gap-6 sm:gap-12">
        <button
          type="button"
          disabled={isLoadingNavigateAccordinglyToFlow}
          onClick={handleContinueBooking}
          className={`${primaryButtonClass} mx-auto w-full`}
        >
          {isLoadingNavigateAccordinglyToFlow ? (
            <>
              <RefreshIcon className="loader h-5 w-5" /> Loading
            </>
          ) : (
            'I understand, continue booking'
          )}
        </button>
        <SkipAndComeBackLater />
      </div>
    </div>
  )
}

export default UpdatePaymentOptions
