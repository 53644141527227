import { usePatient } from '../contexts/PatientProvider'
import type { CarePlan } from '../types/Patient'
import type { ServiceLine } from '../types/ServiceLine'
import useCurrentServiceLine from './useCurrentServiceLine'

const useHasUsedAllFreeSessions = () => {
  const { patient } = usePatient()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()

  const currentCarePlan: CarePlan = patient?.carePlans?.find(
    (cp: CarePlan) =>
      !cp.isIep && cp.displayName === currentServiceLine.displayName
  )

  const isAssessment = !currentCarePlan?.sessions?.some(
    (s) => s.status === 'completed'
  )

  const isBulkModel = currentCarePlan?.bulkModel?.isBulkModel
  const remainingSessionsOnBulk =
    currentCarePlan?.bulkModel?.remainingSessionsOnBulkModel

  // had an initial sessions grant and used up all sessions means no more remaining ones
  const noMoreSessionsRemainingOutsideBulk =
    (isAssessment
      ? currentCarePlan?.remainingSessions <= 1
      : currentCarePlan?.remainingSessions <= 0) &&
    currentCarePlan.allowedSessions > 0

  return isBulkModel
    ? !remainingSessionsOnBulk || noMoreSessionsRemainingOutsideBulk
    : noMoreSessionsRemainingOutsideBulk
}

export default useHasUsedAllFreeSessions
