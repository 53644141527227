import React from 'react'
import { Link, Navigate } from 'react-router-dom'

import { usePatient } from '../../contexts/PatientProvider'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { CarePlan } from '../../types/Patient'
import type { ServiceLine } from '../../types/ServiceLine'
import handleClickSupportEmail from '../../helpers/supportClick'
import { useAuth } from '../../contexts/AuthProvider'

export const BookingMaxedOut: React.FC = () => {
  const { user } = useAuth()
  const { patient } = usePatient()
  const forSelf: boolean = patient?.relationship?.key === 'myself'
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const carePlan: CarePlan = patient?.carePlans.find(
    (cp: CarePlan) =>
      !cp.isIep && cp.displayName === currentServiceLine.displayName
  )

  if (
    !carePlan ||
    (carePlan?.remainingSessions <= 0 && !carePlan.allowedSessions)
  )
    return <Navigate to="/dashboard" />

  return (
    <div className="max-w-6xl items-center justify-center">
      <p className="text-center text-2xl font-medium xs:text-lg">
        You cannot schedule any additional{' '}
        <span className="lowercase">{carePlan.displayName}</span> sessions{' '}
        {!forSelf && (
          <span className="text-cta-default">for {patient.firstName}</span>
        )}{' '}
        at this time.
      </p>
      <div className="flex max-w-lg flex-col gap-10 xs:w-full xs:gap-6">
        <p className="text-center text-base sm:whitespace-pre xs:text-sm">
          It looks like you have used or scheduled all of your covered sessions.
          {'\n'}Please contact{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline xs:text-sm"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>{' '}
          if you have any questions.
        </p>
        <div className="h-0.5 w-full bg-components-fields" />
        <Link
          to="/dashboard"
          className="mb-4 flex self-center text-sm font-medium text-text-secondary underline sm:text-base xs:mb-6"
        >
          Go to my dashboard
        </Link>
      </div>
    </div>
  )
}
