import React from 'react'
import { Link, Navigate } from 'react-router-dom'

import {
  formatPossessiveNoun,
  getMultipleRandomFromArray,
  splitArrayToChunks,
} from '../../helpers/generic'
import { primaryButtonClass } from '../../constants/classConstants'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { Therapist } from '../../types/Therapist'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import GlobalLoader from '../../components/GlobalLoader'
import type { ServiceLine } from '../../types/ServiceLine'
import { usePatient } from '../../contexts/PatientProvider'
import useGetProviders from '../../queries/booking/UseGetProviders'
import TherapistBubbles from './components/TherapistBubbles/TherapistBubbles'
import { useAuth } from '../../contexts/AuthProvider'

const Booking: React.FC = () => {
  const { user } = useAuth()
  const params = new URLSearchParams(window.location.search)
  const changedState: boolean = params.get('changedState') === 'true'

  const { patient } = usePatient()
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const forSelf: boolean = patient?.relationship?.name === 'Myself'

  const { data: result, isLoading: isLoadingTherapists } = useGetProviders({
    clientId: user?.data?.clientId,
    patients: [patient],
  })

  const therapists: Therapist[] = React.useMemo(() => {
    if (!result?.length) return []

    const resultForPatient = result.find((r) => r.patient.id === patient.id)
    const therapists = resultForPatient?.therapists.filter(
      (t) => t.serviceLine === currentServiceLine.displayName
    )

    if (!therapists?.length) return []

    return therapists
  }, [result])

  const matchesMax4: Therapist[] = React.useMemo(() => {
    if (!therapists?.length) return []

    return getMultipleRandomFromArray(
      therapists,
      Math.min(
        4,
        Math.max(
          ...Array.from(
            { length: Math.floor(therapists?.length / 2) },
            (_, i) => (i + 1) * 2
          )
        )
      )
    )
  }, [therapists])

  const matchesMax4Split: Therapist[][] = React.useMemo(
    () => splitArrayToChunks(matchesMax4, 2),
    [matchesMax4]
  )

  if (isLoadingTherapists) return <GlobalLoader />

  if (!patient) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-2xl items-center text-center sm:px-8">
      {!changedState ? (
        <>
          {therapists?.length >= 1 && (
            <p className="text-base font-semibold sm:text-2xl sm:font-medium">
              <span>We found</span>{' '}
              <span
                className={`${forSelf ? '' : 'font-semibold text-cta-default'}`}
              >
                {forSelf ? 'you' : patient?.firstName}
              </span>{' '}
              <span>
                {therapists?.length === 1 ? 'a match' : 'a few matches'},
              </span>{' '}
              Hooray!
            </p>
          )}
          <p className="text-sm font-semibold leading-8 sm:text-2xl">
            Based on your story, we found{' '}
            <span className="text-primaryAccents-orange">
              {therapists?.length}
            </span>{' '}
            {`${currentServiceLine.type} therapist${
              therapists?.length !== 1 ? 's' : ''
            } that match${
              therapists?.length !== 1 ? '' : 'es'
            } what you are looking for.`}
          </p>
        </>
      ) : (
        <div className="flex flex-col gap-4 sm:gap-6">
          <p className="text-sm font-semibold sm:text-2xl">
            Sorry,{' '}
            <span className="text-cta-default">
              {formatPossessiveNoun(patient.firstName)}
            </span>{' '}
            {currentServiceLine.type} therapist does not provide care in{' '}
            {patient.state} at this time
          </p>

          <p className="text-xs font-normal sm:text-base">
            Don't worry, we have plenty of other therapists who do!
          </p>
        </div>
      )}

      <TherapistBubbles
        serviceLine={currentServiceLine}
        matchesMax4Split={matchesMax4Split}
      />
      <div className="flex w-full max-w-lg flex-col gap-4 sm:gap-12">
        {therapists?.length >= 1 && (
          <Link to="providers" className={`${primaryButtonClass} w-full`}>
            View my option{therapists?.length === 1 ? '' : 's'}
          </Link>
        )}
        <SkipAndComeBackLater classes="!mb-0" />
      </div>
    </div>
  )
}

export default Booking
