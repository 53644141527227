import React from 'react'
import { DownloadIcon, InformationCircleIcon } from '@heroicons/react/solid'
import { useNavigate } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthProvider'
import { useGetReceipt } from '../../mutations/payments/GetPaymentReceipt'
import { useToastContext } from '../../contexts/ToastContext'
import Tooltip from '../../components/Tooltip'
import type { Payment } from '../../types/User'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { usePatient } from '../../contexts/PatientProvider'

const PaymentHistoryScreen: React.FC = () => {
  const addToast = useToastContext()
  const navigate = useNavigate()
  const { user } = useAuth()
  const { patient } = usePatient()
  const { mutate: callGetPaymentReceipt } = useGetReceipt()

  const handleDownloadPayment = (paymentId: string) =>
    callGetPaymentReceipt(
      { paymentId },
      {
        onSuccess: (downloadUrl: string) => {
          trackMixPanel({
            eventName: MIXPANEL_EVENT.FILE_DOWNLOADED,
            properties: {
              fileType: 'Payment History',
              patient: patient?.firstName + ' ' + patient?.lastName,
            },
          })
          window.open(downloadUrl, '_blank')
        },
        onError: () => addToast('error', 'Payment receipt not found.'),
      }
    )

  const getTooltipContent = (status: string) => {
    switch (status) {
      case 'PAID':
        return `Your credit card was charged 24 hours prior to your scheduled session.`
      case 'FAILED':
        return `We were unable to process your credit card 24 hours prior to your scheduled session.`
      case 'REFUNDED':
        return `It may take 5-10 business days for the funds to appear in your account.`
      default:
        return ''
    }
  }

  return (
    <div className="mb-auto mt-10 flex w-full flex-col pt-1 pb-6 text-text-primary lg:mt-24 lg:w-2/3">
      <p className="my-8 ml-4 text-lg font-semibold lg:mb-20 lg:text-2xl">
        Payment History
      </p>

      {/* Payments */}
      {!user.payments?.length ? (
        <p className="text-base lg:text-sm">Nothing to show.</p>
      ) : (
        React.Children.toArray(
          user.payments?.map((obj: Payment) => (
            <div
              className={`mb-6 flex flex-col items-start justify-between rounded-none border-0 border-components-fields ${
                obj.chargedAt
                  ? 'bg-white'
                  : obj.lastFailedAt
                  ? 'bg-components-disabled'
                  : ''
              } p-4 text-base font-semibold text-text-primary shadow-subtle lg:flex-row lg:items-center lg:gap-12 lg:rounded-lg lg:border lg:px-10 lg:py-7`}
            >
              <p className="text-left text-base">{obj.patientName}</p>
              <button
                className="text-left text-base underline"
                onClick={() => navigate('/bookings#Previous')}
              >
                {obj.description} on {obj.appointmentDate}
              </button>

              <div className="flex flex-shrink-0 items-center gap-1">
                <div className="flex flex-col">
                  {obj.chargedAt && (
                    <div className="flex items-center gap-1">
                      <p className="text-xs font-normal lg:text-sm">
                        Charged on {obj.chargedAt}
                      </p>
                      <Tooltip
                        content={getTooltipContent('PAID')}
                        position="bottom"
                      >
                        <InformationCircleIcon className="h-4 w-4 text-text-label" />
                      </Tooltip>
                    </div>
                  )}
                  {obj.refundedAt && (
                    <div className="flex items-center gap-1">
                      <p className="text-xs font-normal lg:text-sm">
                        Refunded on {obj.refundedAt}
                      </p>
                      <Tooltip
                        content={getTooltipContent('REFUNDED')}
                        position="bottom"
                      >
                        <InformationCircleIcon className="h-4 w-4 text-text-label" />
                      </Tooltip>
                    </div>
                  )}
                  {obj.lastFailedAt && (
                    <div className="flex items-center gap-1">
                      <p className="text-xs font-normal lg:text-sm">
                        Failed on {obj.lastFailedAt}
                      </p>
                      <Tooltip
                        content={getTooltipContent('FAILED')}
                        position="bottom"
                      >
                        <InformationCircleIcon className="h-4 w-4 text-text-label" />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
              <p className="text-base">{obj.price}</p>
              {(obj.status === 'PAID' || obj.status === 'REFUNDED') && (
                <button onClick={() => handleDownloadPayment(obj.id)}>
                  <DownloadIcon className="h-5 w-5 text-text-primary" />
                </button>
              )}
            </div>
          ))
        )
      )}
    </div>
  )
}

export default PaymentHistoryScreen
