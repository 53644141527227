import React, { useEffect, useState } from 'react'

import DashboardElement from '../DashboardElement'
import { useAuth } from '../../../contexts/AuthProvider'
import type { Patient } from '../../../types/Patient'
import {
  enumeratedElementsFromArray,
  formatPossessiveNoun,
} from '../../../helpers/generic'
import handleClickSupportEmail from '../../../helpers/supportClick'

const NotAllowedToBookAnymore: React.FC = () => {
  const { user } = useAuth()
  const [ineligiblePatients, setIneligiblePatients] = useState([])
  const [notAllowedToBookPatients, setNotAllowedToBookPatients] = useState([])

  const allowedToBookHistories = user.allowedToBookHistories

  useEffect(() => {
    if (!user?.roster?.length) return

    const { patientsNotAllowedToBookNow, ineligibilePatients } =
      user.roster.reduce(
        (
          acc: {
            patientsNotAllowedToBookNow: (Patient | Partial<Patient>)[]
            ineligibilePatients: (Patient | Partial<Patient>)[]
          },
          p: Patient | Partial<Patient>
        ) => {
          if (
            !p.allowedToBook &&
            allowedToBookHistories[p.id]?.[0]?.changedTo === false &&
            allowedToBookHistories[p.id]?.[1]?.changedTo === true
          )
            acc.patientsNotAllowedToBookNow.push(p)

          if (!p.isEligible && !p.isIep) acc.ineligibilePatients.push(p)

          return acc
        },
        {
          patientsNotAllowedToBookNow: [],
          ineligibilePatients: [],
        }
      )

    setNotAllowedToBookPatients(patientsNotAllowedToBookNow)
    setIneligiblePatients(ineligibilePatients)
  }, [user, allowedToBookHistories])

  if (!notAllowedToBookPatients?.length && !ineligiblePatients?.length)
    return null

  return (
    <DashboardElement
      noGap
      title={
        notAllowedToBookPatients?.length ? (
          <span>
            You cannot schedule any sessions for{' '}
            <span className="text-cta-default">
              {enumeratedElementsFromArray(
                notAllowedToBookPatients.map((p: Patient) =>
                  p?.relationship?.key === 'myself' ? 'yourself' : p.firstName
                )
              )}
            </span>{' '}
            at this time.
          </span>
        ) : (
          ineligiblePatients?.length && (
            <span>
              Hmm, looks like{' '}
              <span className="text-cta-default">
                {enumeratedElementsFromArray(
                  ineligiblePatients.map((p) =>
                    p?.relationship?.key === 'myself'
                      ? 'your'
                      : formatPossessiveNoun(p.firstName)
                  )
                )}
              </span>{' '}
              eligibility status may have changed.
            </span>
          )
        )
      }
    >
      <p className="text-base xs:text-sm">
        Please contact the school or sponsoring organization on how services can
        be scheduled. For any other questions, please contact{' '}
        <a
          href="mailto:support@huddleupcare.com"
          className="font-medium text-cta-default underline"
          onClick={() => handleClickSupportEmail(user, location)}
        >
          support@huddleupcare.com
        </a>
        .
      </p>
    </DashboardElement>
  )
}

export default NotAllowedToBookAnymore
