import {
  SERVICE_LINES_ARRAY,
  THERAPIST_NETWORK_INFORMATIONS,
} from '../constants/serviceLine'
import {
  insurancePatientRelationships,
  raceOptions,
  relationshipOptionsProfile,
  resourceFormatOptions,
  resourceStatusOptions,
  states,
} from '../constants/values'
import type { CarePlan, Patient } from '../types/Patient'
import type { ServiceLine } from '../types/ServiceLine'
import type { Insurance, InsuranceRelationship } from '../types/Insurance'
import type { ClientData, Product, User, UserInfo } from '../types/User'
import {
  capitalize,
  convertBackendDateToFrontend,
  fallbackTimeZone,
  getAgeFromBirthdate,
  getGenderIdentity,
} from './generic'
import type { Therapist } from '../types/Therapist'
import {
  mapSessionName,
  mapSessionStatus,
  type Session,
} from '../types/Session'
import type { State } from '../types/State'
import type {
  PreviewResource,
  Resource,
  ResourceFormat,
} from '../types/Resources'
import type { InterestOption } from '../screens/onboarding/WhatBringsYouHere'
import { options } from '../screens/onboarding/WhatBringsYouHere'
import type { Race } from '../types/Profile'
import type { Document } from '../queries/dashboard/GetDocuments'
import type { DisplayDocument } from '../screens/view-patient/tabs/DocumentsTab'
import type { Course } from '../types/Courses'
import { convertBackendTopicForFrontend } from '../queries/resources/GetResourceTopics'

export const toDocument = (document: Document): DisplayDocument => {
  const isPatientPacket: boolean = document.documentType === 'PATIENT_PACKET'
  const isSeparateLoginConsentForm: boolean =
    document.documentType === 'SEPARATE_LOGIN_CONSENT'
  const addedBy: string = document.tags.find((t) => t.key === 'addedBy')?.value

  return {
    id: document.id,
    name: isPatientPacket
      ? 'Patient Packet'
      : isSeparateLoginConsentForm
      ? 'Separate Login Consent Form'
      : document.tags.find((t) => t.key === 'name')?.value,
    date: document.createdAt,
    addedBy:
      isPatientPacket || isSeparateLoginConsentForm ? 'Huddle Up' : addedBy,
  }
}

export const toSession = (
  appointment: any,
  patient: Patient | Partial<Patient>,
  planId: string,
  provider?: Therapist
): Session => {
  const therapist: Therapist = provider || toTherapist(appointment.provider)

  return {
    patientId: patient.id,
    patientName: `${patient.firstName} ${patient.lastName}`,
    startTime: appointment.startTime,
    endTime: appointment.endTime,
    type: therapist.serviceLine,
    therapist,
    zoomLink: appointment?.virtualRoomJoinUrl || null,
    canceledAt: appointment?.canceledAt || null,
    status: mapSessionStatus(appointment.status.toLowerCase()),
    rating: appointment.rating || null,
    id: appointment.id,
    carePlanId: planId,
    name: mapSessionName(therapist.serviceLine, appointment.eventType),
    scheduledBy: {
      name:
        `${appointment?.scheduledByPerson?.firstName} ${appointment?.scheduledByPerson?.lastName}` ||
        'someone else',
      id: appointment?.userId,
    },
    sessionType: appointment.eventType,
  }
}

export const toPatient = (user: any, data: any): Patient => {
  const isIep = data.eligibility.isIep
  const isEligible = data.eligibility.isEligible
  const isDisabled = !isEligible && !isIep
  const isIepOnly =
    isIep && (!isEligible || user.products.every((p: any) => p.isIEP))

  const { carePlans, insurance, insuranceInfo, language } = toCarePlans(
    data.carePlans,
    user,
    { ...data.patient, isIep }
  )

  return {
    id: data.patient.id,
    personId: data.patient.personId,
    isIep,
    isIepOnly,
    isDisabled,
    isEligible,
    externalId: data.patient.externalId,
    firstName: data.patient.firstName,
    lastName: data.patient.lastName,
    preferredName: data.patient.preferredName,
    birthDate: convertBackendDateToFrontend(data.patient.dateOfBirth),
    age: getAgeFromBirthdate(data.patient.dateOfBirth),
    gender: toGender(data.patient.gender),
    genderIdentity: toGenderIdentity(data.patient.genderIdentity),
    preferredPronouns: data.patient.pronouns,
    preferredLanguage: data.patient?.preferredLanguage || language,
    relationship: relationshipOptionsProfile.find(
      (r) => r.key === data.relationship
    ),
    race: toRace(data.patient.race),
    insurance,
    unassociatedInsurance: Boolean(insuranceInfo?.otherInsuranceName),
    streetAddress: data.patient.address.streetAddress,
    apartmentUnit: data.patient.address.apartmentUnit,
    city: data.patient.address.city,
    state: toState(data.patient.address.state),
    zip: data.patient.address.zip,
    carePlans,
    billingCity: data.patient.billingAddress.city,
    billingState: toState(data.patient.billingAddress.state),
    billingZip: data.patient.billingAddress.zip,
    billingStreetAddress: data.patient.billingAddress.streetAddress,
    billingApartmentUnit: data.patient.billingAddress.apartmentUnit,
    email: data.patient.email,
    studentId: data.patient.studentId,
    timeZone: data.patient.timezone || fallbackTimeZone,
    emergencyContact: data.patient.emergencyContact,
    interests: convertBackendTopicForFrontend(data.topics || []),
    userId: data.patient.userId,
    hasSeparateLogin: Boolean(data.patient.invitedAt && data.patient.userId),
    isSeparateLogin: Boolean(
      user.data.id === data.patient.userId && data.patient.invitedAt
    ),
    separateLoginInvitedAt: data.patient.invitedAt,
    ableToBookAndCancel: data.patient.ableToBookAndCancel,
    invitedForSeparateLogin: data.patient.invitedAt && !data.patient.userId,
    checkInTask: data.checkInTask,
    allowedToBook: data.patient.allowedToBook,
  }
}

export const toRoster = (user: any): Patient[] => {
  const result: Patient[] = []

  for (const data of user.roster) {
    result.push(toPatient(user, data))
  }

  return result
}

export const toCarePlans = (
  rawCarePlans: any = [],
  user: User,
  patient: Patient
) => {
  if (!rawCarePlans.length) return { carePlans: [], insurance: null }

  const carePlans: CarePlan[] = []
  let language = null
  let insurance = null
  const insuranceVerified = false

  for (const plan of rawCarePlans) {
    const serviceTypeDisplayName: string = SERVICE_LINES_ARRAY.find(
      (line: ServiceLine) =>
        line.serviceId === plan?.product?.productType?.serviceLineId
    )?.displayName

    const sessions: Session[] = []

    // get sessions of patient
    for (let i = 0; i < plan.appointments?.length; i++) {
      const appointment = plan.appointments[i]

      // expect the following session statuses should not show “IEP meeting”, “Direct Supervision”, and “Created in error”
      if (
        [
          'iep_meeting_attendance',
          'direct_supervision',
          'created_in_error',
        ].includes(appointment.status)
      )
        continue

      sessions.push(
        toSession(appointment, { ...patient, isIep: patient.isIep }, plan.id)
      )
    }

    if (!language) language = plan.language
    if (!insurance) insurance = plan.primaryInsuranceMembership

    const sortedSessions = sessions.sort(
      (a: Session, b: Session) =>
        new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf()
    )

    carePlans.push({
      displayName: serviceTypeDisplayName,
      id: plan.id,
      providerId: plan.providerId,
      sessions: sortedSessions,
      remainingSessions: plan?.remainingFreeSessions,
      allowedSessions: plan.allowedSessions,
      bookingNonSponsoredInformationConfirmed:
        plan.bookingNonSponsoredInformationConfirmed &&
        Boolean(user.paymentMethod),
      billingInformationConfirmed: plan.billingInformationConfirmed,
      dontHaveInsurance: plan.dontHaveInsurance,
      productId: plan.productId,
      bulkModel: plan.bulkModelResult,
      isIep: plan?.product?.productType?.type === 'IEP',
    })
  }

  const sortedCarePlans = carePlans.sort((a: CarePlan, b: CarePlan) => {
    const orderA = SERVICE_LINES_ARRAY.find(
      (sl) => sl.displayName === a.displayName
    ).orderId
    const orderB = SERVICE_LINES_ARRAY.find(
      (sl) => sl.displayName === b.displayName
    ).orderId

    // First, compare by orderId
    if (orderA < orderB) return -1
    if (orderA > orderB) return 1

    // If orderId is equal, compare by allowedSession
    return b.allowedSessions - a.allowedSessions
  })

  const insuranceInfo: Insurance = insurance
    ? {
        insurance: insurance.payer || {
          name: 'Other',
          id: 'other',
          other: true,
        },
        apartmentUnit: insurance.insuredAddressLine_2,
        backInsurance: insurance.backUrl,
        birthDate: convertBackendDateToFrontend(insurance.insuredDateOfBirth),
        city: insurance.insuredCity,
        firstName: insurance.insuredFirstName,
        frontInsurance: insurance.frontUrl,
        gender: capitalize(insurance.insuredGenderAtBirth),
        groupId: insurance.groupNumber,
        lastName: insurance.insuredLastName,
        memberId: insurance.memberId,
        relationship: insurancePatientRelationships.find(
          (rel: InsuranceRelationship) =>
            rel.key === insurance.relationshipToInsured
        ),
        state: states.find((s: State) => s.abbrev === insurance.insuredState)
          ?.name,
        streetAddress: insurance.insuredAddressLine_1,
        zip: insurance.insuredZipCode,
        id: insurance.id,
        otherInsuranceName: insurance?.payerNameEnteredByPatient,
        verified: insuranceVerified,
        isMedicaid:
          insurance?.payer?.name === 'Medicaid' ||
          insurance?.payer?.insuranceType === 'Medicaid',
      }
    : null

  return { carePlans: sortedCarePlans, insurance, insuranceInfo, language }
}

export const toState = (givenState: string) => {
  if (!givenState) return ''

  const state = states.find(
    (s: State) => s?.name === givenState || s?.abbrev.endsWith(givenState)
  )

  return state?.name || givenState
}

export const toUserData = (data: any): UserInfo => ({
  firstName: data.firstName,
  lastName: data.lastName,
  email: data.email,
  id: data.id,
  phoneNumber: data.phoneNumber,
  clientId: data.clientId,
  whatBringsYouHere: options.filter((i: InterestOption) =>
    data?.whatBringsYouHere?.includes(i.key)
  ),
  clientData: toClientData(data.clientData),
  isEligible: data.isAccountHolderEligibile,
  personId: data.personId,
  preferredName: data.preferredName,
  marketingOptInText: data.marketingOptInText,
  marketingOptInEmail: data.marketingOptInEmail,
  bookingUpdatesText: data.bookingUpdatesText,
})

export const toClientData = (clientData: any): ClientData => ({
  clientName: clientData?.name,
  clientType: clientData?.clientType,
  vanityUrl: clientData?.vanityUrl,
  clientSchoolStart: clientData?.startTime,
  clientSchoolEnd: clientData?.endTime,
  importer4thId: clientData?.importer4thId,
  requireEligibilityFile: clientData?.requireEligibilityFile,
})

export const toTherapist = (provider: any): Therapist => {
  const fullName = `${provider.firstName} ${provider.lastName}`

  return {
    id: provider.id,
    name: fullName,
    src: `${import.meta.env.VITE_DOTCOM_URL}/${provider?.avatar}`,
    languages: provider.languages?.map((obj: any) => obj.language) || [],
    licenseCredentials:
      provider.licenses?.map((license: any) => license.jurisdiction) || [],
    networkInformation: provider?.network?.some(
      (n: any) =>
        n.networkStatus.value === 0 &&
        n.networkStatus.description === 'in_network'
    )
      ? THERAPIST_NETWORK_INFORMATIONS.inNetwork
      : THERAPIST_NETWORK_INFORMATIONS.outOfNetwork,
    serviceLine: SERVICE_LINES_ARRAY.find(
      (line: ServiceLine) =>
        line.serviceType === (provider?.serviceLine || provider?.serviceType)
    )?.displayName,
    description: provider.intro || '',
    email: provider.email,
    preferredName: provider.preferredPatientFacingFullName || fullName,
    firstAvailability: provider?.firstAvailability
      ? new Date(provider.firstAvailability).toISOString()
      : null,
    hoursNoticeAssessment: provider?.minimumHoursNoticeBeforeInitialSession,
    hoursNoticeDirectSession: provider?.minimumHoursNoticeBeforeRegularSession,
    licenseCredential: provider?.title,
    takingNewPrivatePatients: provider?.takingNewPrivatePatients,
  }
}

export const toProduct = (product: any): Product => {
  const serviceLine = SERVICE_LINES_ARRAY.find(
    (sl: ServiceLine) => sl.serviceId === product.serviceLineId
  )

  if (!serviceLine) return null

  return {
    additionalSessions: product.additionalSessions,
    id: product.id,
    name: product?.name,
    serviceLine,
    isIep: product.isIEP,
    claimToPatient: product.claimToPatient,
  }
}

export const toResource = (resource: any): Resource => ({
  id: resource.id,
  imageSrc: resource.coverUrl,
  title: resource.title,
  description: resource.description,
  format: resourceFormatOptions.find(
    (rf: ResourceFormat) => rf.id === resource.resourceType
  ),
  duration: resource.duration,
  isBookmarked: resource.bookmarked,
  previewText: resource.previewText,
  type: 'MULTIMEDIA',
})

export const toCourse = (course: any): Course => ({
  id: course.id,
  imageSrc: course.coverUrl,
  title: course.title,
  description: course.description,
  numberOfVideos: course.modulesCount,
  teacherName: course.therapistName,
  teacherCredentials: course.therapistTitle,
  isBookmarked: course.bookmarked,
  previewText: course.previewText,
  type: 'COURSE',
  courseProgresses: course.courseProgresses.map((cp: any) => ({
    status: resourceStatusOptions.find((s) =>
      cp.progress === 100 ? s.id === 'completed' : s.id === 'in_progress'
    ),
    completedAt: new Date(cp.completedAt).toLocaleDateString(),
    completePercentage: cp.progress,
    patient: cp.patient || null,
  })),
})

export const toPreviewResource = (resource: any): PreviewResource => ({
  contentUrl: resource.contentUrl,
  description: resource.description,
  duration: resource.duration,
  format: resourceFormatOptions.find(
    (rf: ResourceFormat) => rf.id === resource.resourceType
  ),
  id: resource.id,
  imageSrc: resource.coverUrl,
  title: resource.title,
  topicKeys: resource.topics,
  isBookmarked: resource.bookmarked,
  type: 'MULTIMEDIA',
})

export const toGender = (genderKey: string) =>
  genderKey ? capitalize(genderKey.toLowerCase()) : null

export const toGenderIdentity = (genderIdentityKey: string) =>
  genderIdentityKey ? getGenderIdentity(genderIdentityKey.toLowerCase()) : null

export const toRace = (raceKey: string) =>
  raceKey ? raceOptions.find((r: Race) => r.key === raceKey) : null
