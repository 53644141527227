import React, {
  useEffect,
  useState,
  useContext,
  createContext,
  useMemo,
} from 'react'
import type { User as FirebaseUser } from 'firebase/auth'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import mixpanel from 'mixpanel-browser'

import { auth } from '../config/firebase'
import type { User } from '../types/User'
import { useGetUserData } from '../mutations/user/GetUserData'
import { initMixpanel } from '../helpers/mixpanel'

initMixpanel()

export interface AuthContextModel {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User>>
  initialized: boolean
  setInitialized: React.Dispatch<React.SetStateAction<boolean>>
  setAuthError: React.Dispatch<React.SetStateAction<string>>
  authError: string
}

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel
)

export const useAuth = (): AuthContextModel => useContext(AuthContext)

export const AuthProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }): JSX.Element => {
  const [user, setUser] = useState<User>(null)
  const [initialized, setInitialized] = useState<boolean>(false)
  const [authError, setAuthError] = useState<string>(null)

  const { mutate: callGetUserData } = useGetUserData()

  const contextValue = useMemo(
    () => ({
      authError,
      setAuthError,
      user,
      setUser,
      initialized,
      setInitialized,
    }),
    [authError, setAuthError, user, setUser, initialized, setInitialized]
  )

  const addInfoToMixpanel = async (u: User) => {
    // Set account holder
    mixpanel.identify(u.data.id)
    setTimeout(() => {
      mixpanel.people.set({
        $distinct_id: u.data.id,
        $first_name: u.data.firstName,
        $last_name: u.data.lastName,
        $email: u.data.email,
        $vanity_url: u.data.clientData.vanityUrl,
        $is_account_holder: true,
        $role: 'Account Holder',
        patients: [],
      })
    }, 500)
  }

  useEffect(() => {
    let unsubscribeAuth = () => {}

    unsubscribeAuth = onAuthStateChanged(auth, async (u: FirebaseUser) => {
      const isUserVerified = u?.emailVerified

      if (!isUserVerified) {
        setUser(null)
        setInitialized(true)
      } else {
        callGetUserData(null, {
          onError: () => {
            setAuthError('auth/something-wrong')
            signOut(auth)
          },
          onSuccess: async (resultUserData: User) => {
            await addInfoToMixpanel(resultUserData)
            setUser(resultUserData)
            setInitialized(true)
          },
        })
      }
    })

    return () => {
      unsubscribeAuth()
    }
  }, [])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
