import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthProvider'
import GlobalLoader from '../GlobalLoader'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'

const RequireNotAuth: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, initialized } = useAuth()
  const searchParams = new URLSearchParams(window.location.search)
  const redirectTo = searchParams.get('redirectTo')

  useEffect(() => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.PAGE_VIEW,
      properties: { url: window.location.href },
    })
  }, [])

  if (user && !user?.isKiddo) {
    if (!user.roster?.length) {
      if (!user.data.whatBringsYouHere?.length)
        return <Navigate to="/what-brings-you-here" replace />
      else return <Navigate to="/onboarding/your-story" replace />
    }
    return (
      <Navigate
        to={redirectTo ? decodeURIComponent(redirectTo) : '/dashboard'}
        replace
      />
    )
  }

  if (!initialized) return <GlobalLoader />

  return <>{children}</>
}

export default RequireNotAuth
