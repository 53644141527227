import React, { useState } from 'react'
import { flushSync } from 'react-dom'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Combobox } from '@headlessui/react'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import cloneDeep from 'lodash.clonedeep'

import { formatPossessiveNoun } from '../../../helpers/generic'
import { useDebounce } from '../../../hooks/useDebounce'
import { useGetInsurances } from '../../../queries/dashboard/Insurances'
import type { SelectInsuranceModel } from '../../../types/Insurance'
import INSURANCE from '../../../assets/images/Insurance.svg'
import {
  comboBoxClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../../constants/classConstants'
import { usePatient } from '../../../contexts/PatientProvider'
import useGoToBookingWithSelfPay from '../hooks/useGoToBookingWithSelfPay'
import { useAuth } from '../../../contexts/AuthProvider'
import type { CarePlan } from '../../../types/Patient'
import { useUpdateProfile } from '../../../mutations/dashboard/UpdateProfile'
import SkipAndComeBackLater from '../../../components/SkipAndComeBackLater'

const InsuranceChoose: React.FC = () => {
  const { user } = useAuth()
  const { patient, setPatient } = usePatient()
  const forSelf: boolean = patient?.relationship?.key === 'myself'
  const navigate = useNavigate()
  const location = useLocation()
  const [goToBookingWithSelfPay] = useGoToBookingWithSelfPay()
  const { mutateAsync: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()

  const [insurance, setInsurance] = useState<SelectInsuranceModel>(
    location?.state?.insurance || null
  )
  const [query, setQuery] = useState<string>('')
  const [blockSearch, setBlockSearch] = useState<boolean>(false)

  const { data: insurances, isFetching } = useGetInsurances({
    name: useDebounce(query, 600),
    blockSearch,
  })

  const handleDontHaveInsuranceClick = () => {
    const newPatient = cloneDeep(patient)

    callUpdateProfile(
      {
        patient: {
          ...patient,
          carePlans: newPatient.carePlans.map((cp: CarePlan) => ({
            ...cp,
            dontHaveInsurance: true,
          })),
        },
        user,
      },
      {
        onSuccess: () => {
          flushSync(() => {
            setPatient(newPatient)
            goToBookingWithSelfPay()
          })
        },
      }
    )
  }

  return (
    <div className="max-w-xl text-center">
      <div className="flex flex-col gap-2 sm:gap-6">
        {!patient?.insurance ? (
          <h2 className="text-lg font-semibold sm:text-2xl">
            Good news. We accept health insurance!
          </h2>
        ) : (
          <h1 className="text-lg font-semibold sm:text-2xl">
            Update {forSelf ? 'your' : 'the'} insurance information
            {!forSelf && (
              <>
                {' '}
                for{' '}
                <span className="text-cta-default">{patient.firstName}</span>
              </>
            )}
          </h1>
        )}
        <p className="text-sm font-semibold sm:text-base">
          Search for{' '}
          <span className={`${forSelf ? '' : 'text-cta-default'}`}>
            {forSelf ? 'your' : formatPossessiveNoun(patient.firstName)}
          </span>{' '}
          insurance to see if the care will be covered.
        </p>
      </div>
      <img
        src={INSURANCE}
        alt="insurance"
        className="mx-auto h-30 w-30 sm:h-50 sm:w-50"
      />
      <div className="mx-auto w-full max-w-md text-left shadow-sm">
        <Combobox as="div" value={insurance} onChange={setInsurance}>
          <Combobox.Label className="text-sm font-semibold sm:text-base">
            Insurance
          </Combobox.Label>
          <div className="relative mt-1">
            <Combobox.Input
              className={`${comboBoxClass} capitalize`}
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(i: SelectInsuranceModel) =>
                i?.name?.toLowerCase?.()
              }
              placeholder="e.g. Blue Cross Blue Shield"
              autoComplete="off"
              onFocus={() => setBlockSearch(false)}
            />
            <Combobox.Button
              className={`${
                !insurances?.length ? 'cursor-default' : ''
              } absolute inset-y-0 right-3 flex items-center rounded-r-md px-2 focus:outline-none`}
            >
              {isFetching ? (
                <RefreshIcon
                  className="loader h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                Boolean(insurances?.length) && (
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )
              )}
            </Combobox.Button>

            {!isFetching && Boolean(insurances) && (
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {(insurances
                  ? [...insurances, { name: 'Other', id: 'other', other: true }]
                  : []
                ).map((insurance) => (
                  <Combobox.Option
                    key={insurance.id}
                    value={insurance}
                    onClick={() => setBlockSearch(true)}
                    className={({ active }) =>
                      `${
                        active ? 'bg-components-fillBorders' : ''
                      } relative cursor-pointer select-none p-4 py-2 xs:text-sm`
                    }
                  >
                    <span className="block truncate capitalize">
                      {insurance?.name?.toLowerCase()}
                    </span>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      </div>
      <div className="flex flex-col gap-6 sm:gap-12">
        <div className="grid grid-cols-2 gap-2 sm:gap-4">
          {!patient?.insurance ? (
            <button
              disabled={isLoadingUpdateProfile}
              onClick={() => handleDontHaveInsuranceClick()}
              className={tertiaryButtonClass}
            >
              {isLoadingUpdateProfile ? (
                <>
                  <RefreshIcon className="loader h-5 w-5 text-text-placeholder" />
                  Loading
                </>
              ) : (
                "I don't have insurance"
              )}
            </button>
          ) : (
            <Link to="../confirm" className={tertiaryButtonClass}>
              <ChevronLeftIcon className="h-5 w-5" />
              Back
            </Link>
          )}
          <button
            disabled={!insurance || isLoadingUpdateProfile}
            onClick={() => {
              navigate('complete', {
                state: {
                  insurance,
                  insuranceFormData:
                    // autofill form if came back from insuranceForm and insurance not changed
                    location?.state?.insurance?.id === insurance.id
                      ? location?.state?.insuranceFormData || null
                      : null,
                },
              })
            }}
            className={primaryButtonClass}
          >
            Next
            <ChevronRightIcon className="h-5 w-5" />
          </button>
        </div>
        {!patient?.insurance && (
          <button
            disabled={isLoadingUpdateProfile}
            onClick={goToBookingWithSelfPay}
            className="text-sm font-medium text-text-secondary underline sm:text-base"
          >
            I don't want to use insurance
          </button>
        )}
        <SkipAndComeBackLater />
      </div>
    </div>
  )
}

export default InsuranceChoose
