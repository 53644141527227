import React, { useEffect } from 'react'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
import { flushSync } from 'react-dom'
import { Navigate, useNavigate } from 'react-router-dom'

import GlobalLoader from '../../components/GlobalLoader'
import { auth } from '../../config/firebase'
import { useGetUserData } from '../../mutations/user/GetUserData'
import type { User } from '../../types/User'
import { useAuth } from '../../contexts/AuthProvider'
import { useGetClientInfo } from '../../queries/onboarding/GetClientInfo'
import { useCreateNewKiddoUser } from '../../mutations/user/CreateNewKiddoUser'

const CreateOrLoginSso: React.FC = () => {
  const { user, setUser, initialized } = useAuth()
  const navigate = useNavigate()

  const { data: clientInfo } = useGetClientInfo()
  const { mutateAsync: callCreateNewKiddoUser } = useCreateNewKiddoUser()
  const { mutate: callGetUserData } = useGetUserData()

  const searchParams = new URLSearchParams(window.location.search)

  const parentPersonId = searchParams.get('parentId')
  const childPersonId = searchParams.get('childId')

  const isKiddo = window.location.origin.includes('kiddo')

  useEffect(() => {
    if (!isKiddo || !parentPersonId || !childPersonId) return

    const ssoAuthentication = async () => {
      const email = `kiddo_${parentPersonId}@huddleupcare.com`
      const password = parentPersonId

      try {
        // sign in with firebase
        await signInWithEmailAndPassword(auth, email, password)
      } catch {
        // register with firebase
        const { user } = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        )

        // get firebase token
        const initialToken: string = await user.getIdToken()

        // create new user in ruby, add claims and validate email
        await callCreateNewKiddoUser({
          parentPersonId,
          token: initialToken,
          clientId: clientInfo?.clientId,
        })

        // get user data
        callGetUserData(null, {
          onError: () => signOut(auth),
          onSuccess: async (resultUserData: User) => {
            flushSync(() => {
              const myPatient = resultUserData?.roster?.find(
                (p) => p.personId === childPersonId
              )

              setUser({ ...resultUserData, isKiddo })

              // already onboarded
              if (myPatient?.state)
                return navigate('/dashboard', { replace: true })

              navigate(`/onboarding/build-profile?patientId=${myPatient?.id}`, {
                replace: true,
              })
            })
          },
        })
      }
    }

    ssoAuthentication()
  }, [isKiddo, parentPersonId, childPersonId])

  if ((user && initialized) || !isKiddo || !parentPersonId || !childPersonId)
    return <Navigate to="/" />

  return <GlobalLoader />
}

export default CreateOrLoginSso
