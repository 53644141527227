import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthProvider'
import { usePatient } from '../../contexts/PatientProvider'
import { useToastContext } from '../../contexts/ToastContext'
import { formatPossessiveNoun } from '../../helpers/generic'
import useGetProviders from '../../queries/booking/UseGetProviders'
import MyTasks from './components/MyTasks'
import NoAvailableTherapists from './messaging/NoAvailableTherapists'
import PickUpSteps from './components/PickUpSteps'
import Roster from './components/Roster'
import Sessions from './components/Sessions'
import RecommendedResources from './components/RecommendedResources'
import CardExpiredOrExpiringModalHandler from './components/CardExpiredOrExpiringModalHandler'
import DashboardFeedbackModal from './components/DashboardFeedbackModal'
import useIsIEPOnly from '../../hooks/useIsIEPOnly'
import BulkRanOutMessaging from './messaging/BulkRanOutMessaging'
import SessionsReplenished from './messaging/SessionsReplenished'
import MessagingLoader from './messaging/MessagingLoader'
import PickUpLoader from './messaging/PickUpLoader'
import NotAllowedToBookAnymore from './messaging/NotAllowedToBookAnymore'
import YesAllowedToBook from './messaging/YesAllowedToBook'
import VerifyingInsurance from './messaging/VerifyingInsurance'

const DashboardScreen: React.FC = () => {
  const iepOnlyExperience = useIsIEPOnly()
  const { user } = useAuth()
  const addToast = useToastContext()
  const { patient, setPatient } = usePatient()
  const location = useLocation()
  const navigate = useNavigate()
  const forSelf = patient?.relationship?.name === 'Myself'
  const { data: resultProviders, isLoading: isLoadingUseGetProviders } =
    useGetProviders({
      patients: user?.roster,
      enabled: Boolean(user?.roster?.length),
      ignoreTakingNewPrivatePatients: true,
      clientId: user?.data?.clientId,
    })

  useEffect(() => {
    if (location?.state?.updatedPhysicalAddress && patient)
      addToast(
        'success',
        `${
          forSelf ? 'Your' : formatPossessiveNoun(patient.firstName)
        } physical address was added successfully!`,
        {
          durationMs: 6000,
          buttonText: `View ${
            forSelf ? 'Your' : formatPossessiveNoun(patient.firstName)
          } Profile`,
          buttonOnClick: () => {
            navigate(`/patient/${patient.id}#Profile`)
          },
        }
      )
    else if (location?.state?.completedQuiz)
      addToast('success', `Task Completed!`, {
        durationMs: 6000,
        buttonText: `Ok`,
      })
    else setPatient(null)
  }, [location])

  if (
    !user?.data?.whatBringsYouHere?.length &&
    !iepOnlyExperience &&
    !user?.roster?.length
  )
    return <Navigate to="/what-brings-you-here" replace />

  return (
    <div className="flex w-full flex-col text-text-primary sm:items-center sm:gap-14 sm:py-24 sm:pb-14 xs:gap-10 xs:py-20">
      {isLoadingUseGetProviders || !resultProviders ? (
        <>
          <MessagingLoader />
          <PickUpLoader />
        </>
      ) : (
        <>
          <SessionsReplenished />
          <BulkRanOutMessaging />
          <VerifyingInsurance />
          <NoAvailableTherapists resultProviders={resultProviders} />
          {/* TODO DCT-3288 reverted */}
          {/* <NewStateProviderMessaging resultProviders={resultProviders} /> */}
          {/* // * (DCT-3486) temporary hide until elig 1.9 release */}
          {/* <ProviderNotAtDCTAnymore resultProviders={resultProviders} /> */}
          {/* <NoCareInState resultProviders={resultProviders} /> */}
          <NotAllowedToBookAnymore />
          <YesAllowedToBook />
        </>
      )}

      <PickUpSteps resultProviders={resultProviders} />
      <MyTasks />
      <Sessions resultProviders={resultProviders} />
      <Roster />
      <RecommendedResources />
      <DashboardFeedbackModal />
      <CardExpiredOrExpiringModalHandler />
    </div>
  )
}

export default DashboardScreen
