import { ExclamationIcon, UserGroupIcon } from '@heroicons/react/outline'
import React, { useEffect, useState } from 'react'

import EMERGENCIES from '../../assets/images/911.svg'
import EMAIL from '../../assets/images/Email.svg'
import TWITTER from '../../assets/icons/Twitter.svg'
import INSTAGRAM from '../../assets/icons/Instagram.svg'
import LINKEDIN from '../../assets/icons/LinkedIn.svg'
import FACEBOOK from '../../assets/icons/Facebook.svg'
import type { Therapist } from '../../types/Therapist'
import { useAuth } from '../../contexts/AuthProvider'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import type { ServiceLine } from '../../types/ServiceLine'
import type { CarePlan, Patient } from '../../types/Patient'
import type { Session } from '../../types/Session'
import ViewTherapistInfoModal from '../../components/Modals/ViewTherapistInfoModal'
import getAvatarSrc from '../../helpers/getAvatarSrc'
import { EXTERNAL_LINKS } from '../../constants/values'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import handleClickSupportEmail from '../../helpers/supportClick'

type RedirectLink = {
  id: number
  title: string
  to: string
}

type SocialMediaLink = {
  id: number
  icon: string
  to: string
}

type ContactTherapists = {
  serviceLine: ServiceLine
  therapists: Therapist[]
  patients: Patient[]
}

export type SelectedTherapist = {
  therapist: Therapist
  patients: Patient[]
}

const links: RedirectLink[] = [
  {
    id: 0,
    title: 'Terms & Conditions',
    to: EXTERNAL_LINKS.TERMS_CONDITIONS,
  },
  {
    id: 1,
    title: 'Privacy Policy',
    to: EXTERNAL_LINKS.PRIVACY_POLICY,
  },
  {
    id: 2,
    title: 'California Privacy Policy',
    to: EXTERNAL_LINKS.CALIFORNIA_PRIVACY_POLICY,
  },
  {
    id: 3,
    title: 'Consent Form',
    to: EXTERNAL_LINKS.CONSENT_FORM,
  },
]

const socialMediaLinks: SocialMediaLink[] = [
  {
    id: 0,
    icon: TWITTER,
    to: 'https://twitter.com/HuddleUpCare',
  },
  {
    id: 1,
    icon: INSTAGRAM,
    to: 'https://www.instagram.com/huddleupcare/ ',
  },
  {
    id: 2,
    icon: LINKEDIN,
    to: 'https://www.linkedin.com/company/huddleupcare/',
  },
  {
    id: 3,
    icon: FACEBOOK,
    to: 'https://www.facebook.com/huddleupcare/ ',
  },
]

const ContactScreen: React.FC = () => {
  const { user } = useAuth()
  const [contactTherapists, setContactTherapists] = useState<
    ContactTherapists[]
  >([])
  const [openTherapistModal, setOpenTherapistModal] = useState<boolean>(false)
  const [selectedTherapist, setSelectedTherapist] =
    useState<SelectedTherapist>(null)

  useEffect(() => {
    if (!user.roster?.length) return

    const result: ContactTherapists[] = [
      {
        serviceLine: SERVICE_LINES_ARRAY.find(
          (sl: ServiceLine) => sl.serviceType === 'social_work'
        ),
        therapists: [],
        patients: [],
      },
      {
        serviceLine: SERVICE_LINES_ARRAY.find(
          (sl: ServiceLine) => sl.serviceType === 'speech_therapy'
        ),
        therapists: [],
        patients: [],
      },
      {
        serviceLine: SERVICE_LINES_ARRAY.find(
          (sl: ServiceLine) => sl.serviceType === 'occupational_therapy'
        ),
        therapists: [],
        patients: [],
      },
    ]

    user.roster.forEach((patient: Patient) => {
      patient?.carePlans
        .filter((cp) => !cp.isIep)
        ?.forEach((cp: CarePlan) => {
          cp?.sessions?.forEach((s: Session) => {
            const currentServiceLine =
              result[
                SERVICE_LINES_ARRAY.find(
                  (sl: ServiceLine) => sl.displayName === cp.displayName
                ).orderId - 1
              ]

            if (
              !currentServiceLine.therapists.some(
                (t: Therapist) => t.id === s.therapist.id
              )
            )
              currentServiceLine.therapists.push(s.therapist)
            currentServiceLine.patients.push(patient)
          })
        })
    })
    setContactTherapists(result)
  }, [user])

  const handleViewTherapistInfo = (
    therapist: Therapist,
    patients: Patient[]
  ) => {
    setSelectedTherapist({
      therapist,
      patients,
    })
    setOpenTherapistModal(true)
  }

  const handleClickTherapistEmail = () => {
    trackMixPanel({
      eventName: MIXPANEL_EVENT.THERAPIST_EMAIL,
      properties: {
        accountId: user.data.id,
      },
    })
  }

  return (
    <div className="mt-11 mb-auto grid w-full grid-cols-2 gap-5 sm:mt-26 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-5xl">
      {/* Help */}
      <div className="col-span-2">
        <p className="mb-2 text-center text-2xl font-bold text-text-primary xs:text-lg">
          We are here to help
        </p>
      </div>

      {/* General questions */}
      <div className="col-span-2 flex items-center rounded-lg border border-components-fields bg-white p-8 shadow-lg xl:col-span-1 xs:justify-start xs:rounded-none xs:border-0 xs:p-4">
        <img src={EMAIL} alt="email" className="xs:h-10 xs:w-10" />
        <div className="ml-5">
          <p className="text-base font-semibold text-text-primary xs:text-sm">
            For any general questions
          </p>
          <a
            href="mailto:support@huddleupcare.com"
            className="text-base font-medium text-cta-default underline xs:text-sm"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
        </div>
      </div>

      {/* Emergencies */}
      <div className="col-span-2 flex items-center rounded-lg border border-components-fields bg-white p-8 shadow-lg xl:col-span-1 xs:justify-start xs:rounded-none xs:border-0 xs:p-4">
        <img src={EMERGENCIES} alt="email" className="xs:h-10 xs:w-10" />
        <div className="ml-5">
          <p className="text-base font-semibold text-text-primary xs:text-sm">
            For any emergencies
          </p>
          <p className="text-base font-semibold text-cta-default xs:text-sm">
            Please call 911
          </p>
        </div>
      </div>

      {/* Info */}
      {user.roster?.some(
        (p) =>
          !p.allowedToBook &&
          p?.carePlans.some((cp) => !cp?.isIep && cp?.sessions?.length)
      ) && (
        <div className="col-span-2 flex items-center justify-center rounded-lg border border-components-fields bg-white p-4 shadow-lg xs:rounded-none xs:border-0">
          <UserGroupIcon className="h-14 w-14 text-text-primary" />
          <p className="ml-4 text-base font-normal text-text-primary">
            Tele-therapy booking feature is only available to those designated
            by their school/organization. Please contact your
            school/organization's administrator if you have any questions.
          </p>
        </div>
      )}

      {/* Info */}
      <div className="col-span-2 flex items-center justify-center rounded-lg border border-components-fields bg-white p-4 shadow-lg xs:rounded-none xs:border-0">
        <ExclamationIcon className="h-14 w-14 text-text-primary" />
        <p className="ml-4 text-base font-normal text-text-primary">
          If you, your child, or someone you are caring for is in crisis, seek
          immediate help. Text 741741 for a crisis text line, call 911, or go to
          your local emergency department. Additional resources can be found{' '}
          <a
            href={EXTERNAL_LINKS.RESOURCES}
            className="text-cta-default underline"
            target="_blank"
          >
            here
          </a>
          .
        </p>
      </div>

      {/* Contact therapist */}
      {contactTherapists.some((ct: ContactTherapists) =>
        Boolean(ct.therapists?.length)
      ) && (
        <div className="col-span-2 flex flex-col items-center gap-6 rounded-lg border border-components-fields bg-white p-8 shadow-lg xs:gap-4 xs:rounded-none xs:border-0 xs:p-4">
          <p className="text-base font-semibold text-text-primary xs:text-sm">
            Want to contact your therapist?
          </p>

          <div className="flex w-full flex-col gap-6">
            {React.Children.toArray(
              contactTherapists
                .filter((ct: ContactTherapists) =>
                  Boolean(ct.therapists?.length)
                )
                .map((ct: ContactTherapists) => (
                  <div className="flex flex-col gap-4 xs:gap-2">
                    <p className="text-center text-base font-semibold capitalize text-text-secondary xs:text-sm">
                      {ct.serviceLine.type} Therapist
                      {ct.therapists?.length > 1 ? 's' : ''}
                    </p>
                    <div className={`border-b ${ct.serviceLine.borderColor}`} />
                    <div className="grid grid-cols-2 gap-2 xs:grid-cols-1">
                      {React.Children.toArray(
                        ct?.therapists?.map((t: Therapist) => {
                          const patients = Array.from(
                            new Set(ct?.patients)
                          ).filter(
                            (p: Patient) =>
                              p?.carePlans.find(
                                (cp: CarePlan) =>
                                  !cp.isIep &&
                                  cp.displayName === ct.serviceLine.displayName
                              ).providerId === t.id
                          )

                          return (
                            <div className="flex items-start gap-4">
                              <button
                                onClick={() =>
                                  handleViewTherapistInfo(t, patients)
                                }
                                className="self-start"
                              >
                                <img
                                  src={t.src}
                                  className="h-14 w-14 rounded-full object-cover"
                                  alt="therapist"
                                />
                              </button>
                              <div className="flex flex-col items-start gap-1">
                                <button
                                  onClick={() =>
                                    handleViewTherapistInfo(t, patients)
                                  }
                                >
                                  <p className="text-sm font-normal text-text-primary xs:text-sm">
                                    {t.preferredName}
                                  </p>
                                </button>
                                <a
                                  href={`mailto:${t.email}`}
                                  className="text-sm font-normal text-cta-default underline xs:text-sm"
                                  onClick={() => handleClickTherapistEmail()}
                                >
                                  {t.email}
                                </a>
                                <div className="mt-1 flex flex-wrap gap-1">
                                  {React.Children.toArray(
                                    patients.map((p: Patient) => (
                                      <PatientPill p={p} />
                                    ))
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        })
                      )}
                    </div>
                  </div>
                ))
            )}
          </div>
        </div>
      )}

      {/* Links */}
      <div className="col-span-2 flex flex-col items-center gap-6 rounded-lg border border-components-fields bg-white p-8 shadow-lg xs:gap-4 xs:rounded-none xs:border-0 xs:p-4">
        <p className="text-base font-semibold text-text-primary">Links</p>

        <div className="flex w-full flex-wrap justify-center gap-2">
          {React.Children.toArray(
            links.map((l: RedirectLink) => (
              <>
                <a
                  href={l.to}
                  target="_blank"
                  className="text-sm text-cta-default underline"
                >
                  {l.title}
                </a>
                <div className="border-r border-components-fields last:hidden" />
              </>
            ))
          )}
        </div>
      </div>

      {/* Social media */}
      <div className="col-span-2 flex flex-wrap items-center justify-center p-6">
        {React.Children.toArray(
          socialMediaLinks.map((s: SocialMediaLink) => (
            <a href={s.to} target="_blank" key={s.id} className="mx-2">
              <img
                src={s.icon}
                className="h-12 w-12 xs:h-6 xs:w-6"
                alt="social-media"
              />
            </a>
          ))
        )}
      </div>

      <ViewTherapistInfoModal
        selectedTherapist={selectedTherapist}
        open={openTherapistModal}
        setOpen={setOpenTherapistModal}
      />
    </div>
  )
}

const PatientPill: React.FC<{ p: Patient }> = ({ p }) => {
  const { user } = useAuth()
  return (
    <div className="flex items-center gap-1 rounded-lg bg-components-disabledFill p-2">
      <img
        src={getAvatarSrc(user, p)}
        alt="patient-avatar"
        className="h-5 w-5"
      />
      <p className="text-sm font-semibold">{p.firstName}</p>
    </div>
  )
}

export { ContactScreen, PatientPill }
